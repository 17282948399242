import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input']

  async populateAndSend(event) {
    event.preventDefault()
    try {
      const token = await this.generateToken()
      this.inputTarget.value = token
      this.element.submit()
    } catch(error) {
      console.error(error)
      this.element.submit()
    }
  }

  // private

    async generateToken() {
      const token = await grecaptcha.execute(grecaptcha.render, { action: this.action })
      return token
    }

    get action() {
      return this.data.get('action')
    }

}