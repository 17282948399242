import "core-js/stable";
import "regenerator-runtime/runtime";

// import frameworks
import { Application } from "stimulus"

// import controllers
import RecaptchaController from '../controllers/recaptcha_controller'

// start the application
const application = new Application
application.register("recaptcha", RecaptchaController)

application.start()

